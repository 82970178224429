import React, { useState } from 'react'
import { Container, Col } from 'react-bootstrap';
import { Logo, Navbar, Social } from '../components';
import {Helmet} from "react-helmet";

const Header = () => {

	const [ fixed, setFixed ] = useState(false);
	const settings = JSON.parse( localStorage.getItem( 'settings' ) );

	function stickyMenu() {
		if( window.scrollY > 180 ) {
			setFixed(true);
		} else {
			setFixed(false);
		}
	}
	window.addEventListener( "scroll", stickyMenu );

	return (
		<>
			<Helmet>
				{ settings && <title>{ `${settings.site_name } | ${ settings.description}` }</title> }
				{ settings && <meta name="description" content={ settings.description } /> }
				<meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
				<link rel="canonical" href="https://jineshpv.com/" />
			</Helmet>
			<header id="header" className="sticky-top-slide">
				<nav className={ fixed ? "primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600 sticky-on" : "primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600" }>
				<Container className="position-relative">
					<Col className="col-auto col-lg-2 d-inline-flex ps-lg-0">
						<Logo />
					</Col>
					<Col className="col-lg-7 navbar-accordion px-0">				
						<Navbar />
										
					</Col>
					<Col className="col-auto col-lg-3 d-flex justify-content-end ps-0">
						<Social />
					</Col>	
				</Container>  
				</nav>          
			</header>
		</>
	)
}

export default Header