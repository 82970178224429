import React from 'react'

const Experiences = ( props ) => {
    const experiences = props.data;
    return (
        <div>
            { experiences && experiences.length > 0 && experiences.map((experienceObj, index) => (
                <div key={ experienceObj.id }>
                    <h3 className="text-5">{ experienceObj.designation }</h3>
                    <p className="mb-2">{ `${ experienceObj.title } / ` }<span className="text-secondary">{ ( experienceObj.current === '1' ) ? `${ experienceObj.duration } - Current` : experienceObj.duration }</span></p>
                    <p className="text-muted">{ experienceObj.excerpt }</p>
                    <hr className="my-4" />
                </div>
            )) }
        </div>
    )
}

export default Experiences