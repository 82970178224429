import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import useToken from "../utils/useToken";
import Content from './Content';
import Tags from './Tags';
import NavLinks from './NavLinks';
import SocialShare from './SocialShare';
import RelatedPosts from './RelatedPosts';
import LeaveReply from './LeaveReply';
import Sidebar from './Sidebar';
import MetaTags from './MetaTags';
import sectionGreenBg from '../assets/images/section-green.webp';
import wrapCenterBg from '../assets/images/wrap-center.webp';
import sectionTopBg from '../assets/images/section-top.webp';
import sectionCommentBg from '../assets/images/bg_pattern.svg';
import sectionGreyBg from '../assets/images/section-grey.webp';

const Listing = () => {

    const { slug } = useParams();
    const [response, setResponse] = useState([]); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken();
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    const fetchContents = async () => {
        try {
            const response = await axios.get( `${siteUrl}/wp-json/api/v2/content/posts/${slug}`, config );

            if (response.status === 401) {
                setResponse('401');
                return;
            }
            setResponse( response.data.item );
            const data = {
                'wpp_id' : response.data.item.id, 
                'sampling' : 0,
                'sampling_rate' : 100
            } 
            await axios.post( `${siteUrl}/wp-json/wordpress-popular-posts/v1/popular-posts`, data, config );
        } catch (error) {
            console.error(error);
        }   
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        fetchContents(); // eslint-disable-next-line	
    },[slug])

    return (
        <>
            <MetaTags data={ response } />
            <section id="blog-page" className="section" style={{ backgroundImage: `url(${ wrapCenterBg })` }}>
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreenBg })`, height: '35px', backgroundColor: '#fff' }}></div>
                <Container>
                    <Row> 
                        <Col lg={9} md={12} className="col-left-custom wow fadeInUp">   
                            <Content data={ response } />
                            <NavLinks data={ response } />  
                            <div className="share_wrapper d-flex align-items-center justify-content-between">
                                <SocialShare title={ response.title } link={ `${window.location.href}/${response.slug}` } /> 
                                <hr className="text-muted" />
                                <span className="share_count">0 Shares</span>   
                            </div>                            
                            <Tags data={ response.tags } />                          
                        </Col>  
                        <Col lg={3} md={12} className="col-right-custom wow fadeInUp">
                            <Sidebar />
                        </Col>              
                    </Row>
                </Container>
            </section>
            <section id="related-articles" className="section bg-light">
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionTopBg })`, height: '35px', backgroundColor: '#f8f9fa' }}></div>
                <Container>
                    <div className="position-relative d-flex text-center mb-5">
                        <h2 className="text-22 text-light opacity-4 text-uppercase fw-600 w-100 mb-0 font-primary wow fadeInUp">Related</h2>
                        <p className="text-10 text-dark fw-600 position-absolute w-100 align-self-center font-secondary lh-base mb-0 wow fadeInUp">You Might Also Like</p>   
                    </div> 
                    <Row> 
                        <Col lg={9} md={12} className="wow fadeInUp">
                            <RelatedPosts data={ response.id } />           
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="comments" className="section" style={{ backgroundImage: `url(${ sectionCommentBg })` }}>
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreyBg })`, height: '35px' }}></div>
                <Container>
                    <div className="position-relative d-flex text-center mb-5">
                        <h2 id="comments" className="text-22 text-light opacity-4 text-uppercase fw-600 w-100 mb-0 font-primary wow fadeInUp">Discuss</h2>
                        <p className="text-10 text-dark fw-600 position-absolute w-100 align-self-center font-secondary lh-base mb-0 wow fadeInUp">Comments</p>   
                    </div> 
                    <Row> 
                        <Col lg={9} md={12} className="wow fadeInUp">
                            <LeaveReply id={ response.id } />                           
                        </Col>
                    </Row>
                </Container>
            </section>
        </> 
    )
}

export default Listing