import React, { useEffect } from "react";
import Preloader from '../../components/Preloader';
import Banner from '../../components/Banner';
import Listing from './Listing';
  
const Blog = () => {

    useEffect(() => {
		document.body.classList.add('inner-page', 'blog')
		return () => {
		  document.body.classList.remove('inner-page', 'blog')
		}
	}, [])

    return (
        <>
			<Preloader />
			<Banner page={{ title: 'Blog' }} />
			<Listing type="blog" />
		</>
    );
};
  
export default Blog;