import { useState } from 'react';

const useToken = () => {

    const getToken = () => { 

        const tokenString = sessionStorage.getItem( 'token' );
        const userToken = tokenString;
        return userToken
    }

    const [token, setToken] = useState( getToken() )

    const saveToken = userToken => { 
        sessionStorage.setItem( 'token', userToken );
        setToken( userToken.token );
    };

    return { 
        setToken: saveToken,
        token
    }
}

export default useToken;