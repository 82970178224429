import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import "./skeleton.css";

export default function HomeSkeleton({ type }) {
    const eCounter = 6;
    const rCounter = 4;
    const sCounter = 16;
    const esCounter = 3;
    const SliderSkeleton = () => (
        <Container className="py-5 BannerSk">
            <Row className="py-4">
                <Col lg={6} sm={12}>
                    <div className="BannerSkTitle SkBgColor"></div>
                    <p className="mt-3 BannerSkTyped SkBgColor"></p>
                    <div className="mt-3 BannerSkLink SkBgColor"></div>
                    <div className="mt-3 BannerSkLink SkBgColor"></div>
                </Col>
                <Col lg={6} sm={12}>
                    <div className="p-3 BannerSkImg SkBgColor SkBorderRadius"></div>
                </Col>
            </Row>
        </Container>
    );

    const AboutSkeleton = () => (
        <>
            <Row>
                <div className="col-lg-8">
                    <div className="mb-3 BannerSkLink SkBgColor"></div>
                    <div className="SkBgColor BannerSkPara"></div>
                </div>
                <Col className="col-lg-4 mt-4 mt-lg-0">
                    <div className="featured-box style-4">
                        <div className="featured-box-icon SkBgColor SkBorderRadius"></div>
                        <div className="SkBgColor BannerSkLink"></div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="col-6 col-lg-3">
                    <div className="SkBgColor BannerSkLink mb-3"></div>
                    <div className="SkBgColor BannerSkLink"></div>
                </Col>
                <Col className="col-6 col-lg-3">
                    <div className="SkBgColor BannerSkLink mb-3"></div>
                    <div className="SkBgColor BannerSkLink"></div>
                </Col>
                <Col className="col-6 col-lg-3">
                    <div className="SkBgColor BannerSkLink mb-3"></div>
                    <div className="SkBgColor BannerSkLink"></div>
                </Col>
                <Col className="col-6 col-lg-3">
                    <div className="SkBgColor BannerSkLink mb-3"></div>
                    <div className="SkBgColor BannerSkLink"></div>
                </Col>
            </Row>
        </>
    )
    
    const ExpertiseSkeleton = (index) => (
        <Col lg={6} sm={12} key={ index }>
            <div className="featured-box text-center px-md-4">
                <div className="featured-box-icon SkBgColor SkBorderRadius BannerSkImgSmall"></div>
                <div className="SkBgColor BannerSkLink mb-3"></div>
                <p className="SkBgColor BannerSkExpt"></p>
            </div>
        </Col>
    );

    const ResumeSkeleton = (index) => (
        <div key={ index }>
            <div className="SkBgColor BannerSkLink mb-3"></div>
            <div className="SkBgColor BannerSkLink mb-3"></div>
            <p className="SkBgColor BannerSkLink"></p>
            <hr className="my-4" />
        </div>
    );

    const SkillSkeleton = (index) => (
        <Col key={ index }>
            <div className="SkBgColor BannerSkLink mb-3"></div>
        </Col>
    );

    const ThreeEsSkeleton = (index) => (
        <div className="call-out" key={ index }>
            <div className="SkBgColor BannerSkLink mb-3"></div>
            <p className="SkBgColor BannerSkExpt"></p>
        </div>
    );

    if( type === "slider" ) return <SliderSkeleton />;
    else if( type === "about" ) return <AboutSkeleton />;
    else if( type === "expertise" ) return Array.from({ length: eCounter }, (_, index) => <ExpertiseSkeleton key={index} />);
    else if( type === "resume" ) return Array.from({ length: rCounter }, (_, index) => <ResumeSkeleton key={index} />);
    else if( type === "skill" ) return Array.from({ length: sCounter }, (_, index) => <SkillSkeleton key={index} />);
    else if( type === "3es" ) return Array.from({ length: esCounter }, (_, index) => <ThreeEsSkeleton key={index} />);
}