import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Preloader from '../../components/Preloader';
import Banner from '../../components/Banner';
import Listing from './Listing';
import { capitalizeWords } from '../../utils/Helper'

const Tags = () => {
    const { slug } = useParams(); 
    const title = capitalizeWords( slug.replace( /-/g, ' ' ) );

    useEffect(() => {
		document.body.classList.add('inner-page', 'blog')
		return () => {
		    document.body.classList.remove('inner-page', 'blog')
		}
	}, [])

    return (
        <>
			<Preloader />
			<Banner page={{ title: `Tag: ${title}` }} />
			<Listing type="tag" />
		</>
    );
}

export default Tags