import DOMPurify from 'dompurify'

export function capitalizeWords(str) {
    
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function SanitizedHTML({ html, tag, className }) {

    const sanitizedHTML = DOMPurify.sanitize(html);

    if( tag === 'div' )
        return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
    else if( tag === 'span' )
        return <span className={className} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
    else if( tag === 'h1' )
        return <h1 className={className} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
    else
        return <p className={className} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}

export function Mailto({ email, subject, body, ...props }) {
    return (
        <a className="text-dark" href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
            {props.children}
        </a>
    );
}