import React from 'react';
import { Anchor } from 'react-bootstrap';
import LogoImg from '../logo.svg';

const Logo = () => {
  return (
    <Anchor href="/" className="logo">
        <img src={ LogoImg } alt="Jinesh P.V" width="100%" height="100%" />
    </Anchor>
  )
}

export default Logo