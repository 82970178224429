import React, { useState, useEffect } from 'react'

const Preloader = () => {

  const [loading, setLoading] = useState(false);

	useEffect(() =>{
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	},[]);

  return (
		<>
		{ loading && 
			<div className="preloader">
				<div className="lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div> }
		</>
	)
}

export default Preloader
