import React from 'react'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    
    const pageNumbers = [];
    for( let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination_wrapper">
            <nav>
                <button className={`text-4 prev ${ currentPage === 1 ? "disabled" : "" }`} onClick={ () => onPageChange(currentPage-1) } disabled={ currentPage === 1 } >{ `<` }</button>
                { pageNumbers.map((number, i) =>( 
                    <button className={`text-4 ${ currentPage === number ? "current" : "" }`} key={number} onClick={ () => onPageChange(number) } disabled={ currentPage === number } >{ number }</button>
                ))}
                <button className={`text-4 next ${ currentPage === totalPages ? "disabled" : "" }`} onClick={ () => onPageChange(currentPage+1) } disabled={ currentPage === totalPages } >{ `>` }</button>
            </nav>
        </div>
    )
}

export default Pagination