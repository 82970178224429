import React, { useEffect } from "react";
import Preloader from '../../components/Preloader';
import Banner from '../../components/Banner';
import EducationExperience from './EducationExperience';
import SkillsContent from '../../components/SkillsContent';

const Resume = () => {
	useEffect(() => {
		document.body.classList.add('inner-page', 'resume')
		return () => {
		  document.body.classList.remove('inner-page', 'resume')
		}
	}, [])

    return (
        <>
			<Preloader />
			<Banner page={{ title: 'Resume' }} />
			<EducationExperience />
			<SkillsContent />
		</>
    );
}

export default Resume