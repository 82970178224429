import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SanitizedHTML } from '../utils/Helper';

const NavLinks = (props) => {

    const post = props.data;
    const prevLink = `/blog/${post.prev_post_link}`;
    const nextLink = `/blog/${post.next_post_link}`;
    const tag = 'span';

    return (
        <div className="nav_links wow mb-5 mt-5 fadeInUp">
            <Container>
                <Row>
                    <div className="col-lg-6 col-md-12 wow fadeInUp d-flex flex-column align-items-start">
                        <button type="button" className="fw-700 text-5 p-0">Previous Post</button>
                        <Link to={ prevLink } className="text-muted"><SanitizedHTML html={ post.prev_post_title } tag={ tag } /></Link>
                    </div>
                    <div className="col-lg-6 col-md-12 wow fadeInUp d-flex flex-column align-items-end">    
                        <button type="button" className="fw-700 text-5 p-0">Next Post</button>
                        <Link to={ nextLink } className="text-muted"><SanitizedHTML html={ post.next_post_title } tag={ tag } /></Link>
                    </div>
                </Row> 
            </Container>
        </div>
    )
}

export default NavLinks
