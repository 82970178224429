import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Preloader from '../../components/Preloader';
import Banner from '../../components/Banner';
import BlogDetails from "../../components/BlogDetails";
import { capitalizeWords } from '../../utils/Helper'

const Details = () => {

    const { slug } = useParams(); 
    const title = capitalizeWords( slug.replace( /-/g, ' ' ) );
    
    useEffect(() => {

        document.body.classList.add('inner-page', 'blog');
        document.body.classList.add('inner-page', 'blog-details');

        return () => {
            document.body.classList.remove('inner-page', 'blog')
            document.body.classList.remove('inner-page', 'blog-details')
        }
    }, [slug])
    
    return (
        <>
			<Preloader />
			<Banner page={{ title: title }} />
            <BlogDetails />
		</>
    );
}

export default Details