import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionTopBg from '../../assets/images/section-top.webp';
import HomeSkeleton from '../../components/skeleton/HomeSkeleton';

const ExpertiseSection = () => {

    const [expertise, setExpertise] = useState([]); 
    const [isLoading, setIsLoading] = useState(true); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;

    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchData() {
        setIsLoading(true);
        try {
            const response = await axios.get(`${siteUrl}/wp-json/api/v2/profile/expertises`, config)
            setExpertise(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line	
    },[])

    return (
        <section id="services" className="section bg-light">
            <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionTopBg })`, height: '35px', backgroundColor: '#f8f9fa' }}></div>
            <Container> 
                <div className="position-relative d-flex text-center mb-5">
                    <h2 className="text-22 text-light opacity-4 text-uppercase fw-600 w-100 mb-0 font-primary wow fadeInUp">What I Do?</h2>
                    <p className="text-10 text-dark fw-600 position-absolute w-100 align-self-center font-secondary lh-base mb-0 wow fadeInUp">Expertise and Knowledge</p>   
                </div>        
                <Row className="gy-5 mt-5">
                    { isLoading ? (
                        <HomeSkeleton type="expertise" />
                    ) : (       
                        expertise && expertise.length > 0 && expertise.map((expertiseObj, index) => (                        
                        <Col lg={4} sm={6} className="wow fadeInUp" key={ expertiseObj.id }>
                            <div className="featured-box text-center px-md-4">
                                <div className="featured-box-icon text-primary text-13"><img src={ expertiseObj.icon_url } alt="" height={ '70px' }/></div>
                                <h3 className="text-6 fw-600 mb-3">{ expertiseObj.title }</h3>
                                <p className="text-muted mb-0">{ expertiseObj.excerpt }</p>
                            </div>
                        </Col>
                    ))) }                   
                </Row>
            </Container>
        </section>
    );
}

export default ExpertiseSection
