import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionGreyBg from '../../assets/images/section-top.webp';
import sectionBg from '../../assets/images/section-bg2.webp';
import HomeSkeleton from '../../components/skeleton/HomeSkeleton';

const My3eSection = () => {

    const [my3e, setMy3e] = useState([]);  
    const [isLoading, setIsLoading] = useState(true); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;

    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchData() {
        setIsLoading(true);
        try {
            const response = await axios.get(`${siteUrl}/wp-json/api/v2/profile/3es`, config)
            setMy3e(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line	
    },[])

    return (
        <>
            {/* <style>{ `.call-out.top::after { content: url(${ arrowTop }); } .call-out.middle::after { content: url(${ arrowBottom }); }` }</style> */}
            <section id="my-3e" className="section bg-dark-blue" style={{ backgroundImage: `url(${ sectionBg })` }}> 
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreyBg })`, height: '35px' }}></div>    
                <Container>
                    <div className="position-relative d-flex text-center">
                        <h2 className="text-22 text-light opacity-4 text-uppercase fw-600 w-100 mb-0 font-primary wow fadeInUp">summary</h2>
                        <p className="text-10 text-dark fw-600 position-absolute w-100 align-self-center font-secondary lh-base mb-0 wow fadeInUp">My 3E's</p>
                    </div>
                    <Row className="g-5">
                        <Col lg={12} className="text-center text-lg-start wow fadeInUp">
                            <div className="call-outs">
                            { isLoading ? (
                                <HomeSkeleton type="3es" />
                            ) : (  my3e && my3e.length > 0 && my3e.map((my3eObj, index) => (
                                <div className="call-out" key={ my3eObj.id }>
                                    <div className="call-out-image">
                                        <img src={ my3eObj.image_url } alt={ my3eObj.title } />
                                    </div>
                                    <div className="link">{ my3eObj.title }</div>
                                    <p className="desc">{ my3eObj.excerpt }</p>
                                </div>
                                )))
                            }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
export default My3eSection