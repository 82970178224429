import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import './assets/css/style.css';
import './assets/css/animate.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/main.css';
import 'enlighterjs/dist/enlighterjs.min.css';
import { Home, About, Blog, Contact, Resume,  } from './pages';
import Details from './pages/blog/Details';
import Categories from './pages/blog/Categories';
import Tags from './pages/blog/Tags';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Preloader from './components/Preloader';
import Header from './components/Header';
import Footer from './components/Footer';
import useToken from './utils/useToken';
import useIp4 from './utils/useIp4';

const App = () => {	

	const { token, setToken } = useToken();
	const { ip4, setIp4 } = useIp4();
	const siteUrl = process.env.REACT_APP_API_SITE_URL;

	const storeToken = useCallback(async () => {
		const consumerKey = process.env.REACT_APP_API_CONSUMER_KEY;
		const consumerSecret = process.env.REACT_APP_API_CONSUMER_SECRET;
		const config = {
			headers:{
				'Content-type': 'application/json; charset=UTF-8'
			}
		};

		const postData = async () => {
			try {
				const response = await axios.post( `${siteUrl}/wp-json/api/v2/profile/oauth/token`, {
					"consumer_key" : consumerKey,
					"consumer_secret" : consumerSecret
				}, config );
			  	return response.data; 
			} catch (error) {
			  	console.error('Error:', error);
			  	throw error;
			}
		};
		postData()
		.then( ( data ) => {
			if( data.token_type === 'Bearer' ) {					
				return setToken( data.access_token );
			}
		})
		.catch( ( err ) => {
			console.log( err.message );
		});						
	}, [setToken, siteUrl]);

	useEffect( () => {
		storeToken();	
		// eslint-disable-next-line		
	}, [storeToken]);
	
	const storeIp = async () => {       
        try {
            const response = await axios.get( 'https://geolocation-db.com/json/' ); 
            return setIp4( response.data.IPv4 );
        } catch (error) {
            console.error(error);
        }					
	}

	useEffect( () => {
		return () => { 
			if( !ip4 ) { 
				storeIp();
			}
		}	
		// eslint-disable-next-line		
	}, [storeIp]);

	const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    async function fetchSettings() {
        try {
			if( token ) { 
				const settings = await axios.get(`${siteUrl}/wp-json/api/v2/content/site-metadata`, config);
				localStorage.setItem( 'settings', JSON.stringify( settings.data.items ) );
			}
        } catch (error) {
            console.error(error);
        }
    }

	useEffect(() => {
        fetchSettings();
        // eslint-disable-next-line	
    },[token])

  	return (
		<Router>
			<Preloader />
			<div id="main-wrapper"> 
				<Header />				
				<Routes>
					<Route exact path='/' element={ <Home /> } />
					<Route exact path='/about-me' element={ <About/> } />
					<Route exact path='/blog' element={ <Blog/> } />
					<Route exact path='/resume' element={ <Resume/> } />
					<Route exact path='/connect-me' element={ <Contact/> } />
					<Route path="/blog/:slug" element={ <Details/> } />
					<Route path="/category/:slug" element={ <Categories/> } />
					<Route path="/tag/:slug" element={ <Tags/> } />
				</Routes>
				<Footer />
			</div>
		</Router>	
	);
}

export default App;