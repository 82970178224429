import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionGreenBg from '../assets/images/section-green.webp';
import wrapCenterBg from '../assets/images/wrap-center.webp';
import { Mailto } from '../utils/Helper';
import HomeSkeleton from './skeleton/HomeSkeleton';

const PageContent = ({ page, type }) => { 
    
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState({
        id: null,
        title: null,
        content: null,
        email_address: null,
        date_of_birth: null,
        place: null,
        phone_number: null,
        years_of_experience: null,
    });
    const pageId = page.id;
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchContents() {

        try {
            setIsLoading(true);
            const response = await axios.get(`${siteUrl}/wp-json/wp/v2/pages/${pageId}`, config);
            setContent({
                id: response.data.id,
                title: response.data.full_name,
                content: response.data.excerpt,
                email_address: response.data.email_address,
                date_of_birth: response.data.date_of_birth,
                place: response.data.place,
                phone_number: response.data.phone_number,
                years_of_experience: response.data.years_of_experience,
            });
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchContents();
        // eslint-disable-next-line	
    },[])

    return (
        <>
            { content.id !== null &&  
            <section id="about" className="section" style={{ backgroundImage: `url(${ wrapCenterBg })` }}>
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreenBg })`, height: '35px' }}></div>          
                <Container> 
                    { isLoading ? (
                        <HomeSkeleton type="about" />
                    ) : (    
                    <>           
                        <Row>
                            <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
                                <p className="text-5">{ content.content }</p>
                            </div>
                            <Col className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="featured-box style-4">
                                    <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                                        <span className="wow heartBeat" data-wow-delay="1.3s">{ content.years_of_experience }</span>
                                    </div>
                                    <h3 className="text-7 wow rubberBand" data-wow-delay="2s">Years of <span className="fw-700">Experience</span></h3>
                                </div>
                            </Col>
                        </Row>
                        <Row className="gy-3 mt-4">
                            <Col className="col-6 col-lg-3 wow fadeInUp">
                                <p className="text-muted fw-500 mb-0">Phone:</p>
                                <p className="text-4 text-dark fw-600 mb-0">{ content.phone_number }</p>
                            </Col>
                            <Col className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                                <p className="text-muted fw-500 mb-0">Email:</p>
                                <p className="text-4 fw-600 mb-0"><Mailto email={ content.email_address } subject="Hello" body="Hello Jinesh P.V">{ content.email_address }</Mailto></p>
                            </Col>
                            <Col className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                                <p className="text-muted fw-500 mb-0">Date of birth:</p>
                                <p className="text-4 text-dark fw-600 mb-0">{ content.date_of_birth }</p>
                            </Col>
                            <Col className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                                <p className="text-muted fw-500 mb-0">From:</p>
                                <p className="text-4 text-dark fw-600 mb-0">{ content.place }</p>
                            </Col>
                        </Row>
                    </>
                )}    
                </Container>
            </section>
            }
        </>
    )
}

export default PageContent
