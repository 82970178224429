import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
	
	const [classes, setClasses] = useState(['nav-link', 'smooth-scroll'] );
	const [buttonClasses, setButtonClasses] = useState( ['navbar-toggler', 'collapsed', 'ms-auto'] );
	const [isOpen, setIsOpen] = useState(false);
	const currentUrl = window.location.href;
	const stringExistsInUrl = currentUrl.includes( 'category' ) || currentUrl.includes( 'blog' ) || currentUrl.includes( 'tag' );

	const toggleMenu = () => {
		setIsOpen( !isOpen );
		if( !isOpen ) {
			setButtonClasses(prevClasses => {
				if (!prevClasses.includes('show')) {
				return [...prevClasses, 'show'];
				}
				return prevClasses; 
			});
		} else {
			setButtonClasses( prevClasses => {
				if (prevClasses.includes('show')) {
				  return prevClasses.filter(item => item !== 'show');
				}
				return prevClasses;
			});
		}
	};

	const closeMenuOnMobile = () => {
		if ( window.innerWidth <= 1150 ) {
			setIsOpen(false);
			const element = document.querySelector( '.navbar-toggler.show' );
			if ( element ) {
				element.classList.remove( 'show' );
			}
		}
	};

	useEffect(() => {
		if( stringExistsInUrl ) {
			setClasses(prevClasses => {
				if (!prevClasses.includes('active')) {
				  return [...prevClasses, 'active'];
				}
				return prevClasses; 
			});
		} else {
			setClasses( prevClasses => {
				if (prevClasses.includes('active')) {
				  return prevClasses.filter(item => item !== 'active');
				}
				return prevClasses;
			});
		}
		setIsOpen( false );
    },[stringExistsInUrl]);
	const dynamicClassName = classes.join(' ');
	const dynamicButtonClassName = buttonClasses.join(' ');

	return (
		<>
			<button onClick={toggleMenu} className={ dynamicButtonClassName } type="button" data-bs-toggle="collapse" data-bs-target="#header-nav">
				<span></span><span></span><span></span>
			</button>
			<div id="header-nav" className="collapse navbar-collapse justify-content-center">
				<ul className="navbar-nav">
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/">Home</NavLink></li>
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/about-me">About</NavLink></li>
					<li className="nav-item"><NavLink className={ dynamicClassName } activeclassname="active" to="/blog">Blog</NavLink></li>
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/resume">Resume</NavLink></li>
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/connect-me">Contact</NavLink></li>
				</ul>
			</div>
			{ isOpen && (<div id="mobile-nav" className="collapse navbar-collapse justify-content-center">
				<ul className="navbar-nav">
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/" onClick={closeMenuOnMobile}>Home</NavLink></li>
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/about-me" onClick={closeMenuOnMobile}>About</NavLink></li>
					<li className="nav-item"><NavLink className={ dynamicClassName } activeclassname="active" to="/blog" onClick={closeMenuOnMobile}>Blog</NavLink></li>
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/resume" onClick={closeMenuOnMobile}>Resume</NavLink></li>
					<li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/connect-me" onClick={closeMenuOnMobile}>Contact</NavLink></li>
				</ul>
			</div>
			)}
		</>
	);
};
  
export default Navbar;