import React from 'react'
import { Button } from 'react-bootstrap';

const DownloadPDFButton = () => {
    const settings = JSON.parse( localStorage.getItem( 'settings' ) );
    const downloadPDF = () => {
        const link = document.createElement( 'a' );
        link.href = settings && settings.resume_link; 
        link.download = settings && settings.resume_link;
        link.target = '_blank';
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    };

    return (
        <Button type="submit" className="btn btn-dark rounded-0" onClick={ downloadPDF } >Download Resume</Button>
    )
}

export default DownloadPDFButton