import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import sectionGreenBg from '../../assets/images/section-green.webp';
import wrapCenterBg from '../../assets/images/wrap-center.webp';
import BlogList from '../../components/BlogList';
import Pagination from '../../components/Pagination'
import useToken from "../../utils/useToken";
import Preloader from '../../components/Preloader';
import Sidebar from '../../components/Sidebar';

const Listing = ( term ) => {

    const { slug } = useParams(); 
    const searchStr = new URLSearchParams( window.location.search ).get( 's' );
    const terms = ( term.type !== 'blog' ) ? `&${term.type}=${slug }` : '';
    const search = searchStr ? `&s=${searchStr}` : '';
    const [response, setResponse] = useState([]); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken(); 
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    //Pagination Logic
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const postsPerPage = 10;

    const [isLoading, setIsLoading] = useState(false);

    async function fetchContents() {

        setIsLoading(true);
        try {
            
            const response = await axios.get( `${siteUrl}/wp-json/api/v2/content/posts?paged=${currentPage}&per_page=${postsPerPage}${terms}${search}`, config ); 

            if (response.status === 404) {
                setResponse('404');
                return;
            }

            setResponse( response.data.items );
            const totalPages = parseInt( response.data.total_posts ) / postsPerPage;
            setTotalCount( Math.round(totalPages ) )
        } catch (error) {
            console.error(error);
        }finally {
            setIsLoading(false);
        } 
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        fetchContents(); // eslint-disable-next-line	
    },[slug, currentPage, searchStr])

    return (
        <>
            <section id="blog-page" className="section" style={{ backgroundImage: `url(${ wrapCenterBg })` }}>
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreenBg })`, height: '35px', backgroundColor: '#ffffff' }}></div>
                <Container>
                    <Row>                       
                        <div className="col-lg-9 col-md-12 col-left-custom wow fadeInUp">   
                            <BlogList data={ response } />  
                            {( totalCount > 1 ) && <Pagination currentPage={ currentPage } totalPages={ totalCount } onPageChange={ handlePageChange } /> }                                      
                            { isLoading && <Preloader /> }
                        </div>  
                        <div className="col-lg-3 col-md-12 col-right-custom wow fadeInUp">
                        <Sidebar />
                        </div>              
                    </Row>
                </Container>
            </section>
        </> 
    )
}

export default Listing