import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import useToken from '../utils/useToken';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SanitizedHTML } from '../utils/Helper';

const Content = (props) => {

    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const post = props.data;
    const [likes, setLikes] = useState({});
    const [isButtonClicked, setIsButtonClicked] = useState([]); 
    const { token } = useToken(); 

    const doSavePostInHeart = async (postId, currCount, nonce) => { 
        const likeCount = parseInt((likes[postId] || currCount) + 1);
        setLikes({ ...likes, [postId]: likeCount })

        if( !isNaN(likeCount) ) {
            const config = {
                headers:{
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'no-cors'
            };
            const params = {
                'liked_count' : likeCount,
                'post_id' : postId
            }
            try {
                const response = await axios.put( `${siteUrl}/wp-json/api/v2/content/posts/liked`, params, config ); 
                const args = {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                };
                if( response.data.status === 'success' ) {
                    toast.success( response.data.message, args );
                } else {
                    toast.error(response.data.message, args);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const handleIsButtonClick = (index, postId) => {    
        setIsButtonClicked( prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    }

    useEffect(() => {
        doSavePostInHeart(); // eslint-disable-next-line	
    },[])

    return (
        post && 
        <div className="box post-wrapper wow p-5 mb-5 fadeInUp">
            <div className="post-meta d-flex align-items-center pb-4 text-muted fw-500 text-uppercase">
                <span className="post-author">
                    <Link to="/about-me" className="d-flex align-items-center text-muted">
                        { post.post_author_url && <img src={ post.post_author_url } alt={ post.post_author_name } width={40} className="img-fluid rounded-circle me-2" /> }
                        <span>{ post.post_author_name }</span>
                    </Link>
                </span>
                <span className="post-date">{ post.post_date }</span>                
                <span className="post-reading-time">{ post.reading_time }</span>
                <span className="post-comment-count"><Link className="text-muted" to={ `/blog/${post.slug}/#comments` }>{ post.comment_count }</Link></span>
                <span className="post-view-count">{ post.page_views }</span>                
            </div>
            <ToastContainer />
            <div className="post-image mb-5">
                { post.featured_image && <img src={ post.featured_image } alt={ post.title } className="img-fluid" /> }
                <span className="liked-count-wrap text-muted">
                    <button onClick={() => [doSavePostInHeart(post.id, parseInt(post.liked_count), post.nonce), handleIsButtonClick(0, post.id)]} disabled={isButtonClicked[0]}>
                        <FontAwesomeIcon icon={ !likes[post.id] ? farHeart : faHeart }/>
                    </button>                                   
                    <FormLabel>{ likes[post.id] || post.liked_count }</FormLabel>
                </span>
            </div>
            <div className="post-meta d-flex align-items-center text-muted fw-500 text-uppercase">
                { post.categories && <span className="post-category">
                    <span>Categories</span>
                    { post.categories.map((cat, index) => (
                        <Link className="text-muted" key={ index } to={ `/category/${cat.slug}` }>{ cat.name }</Link>
                    )) }
                </span> }
            </div>
            <SanitizedHTML className="text-3 mt-4 text-muted post-content" html={ post.content } tag="div" />
        </div>
    )
}

export default Content