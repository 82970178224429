import React, { useEffect } from "react";
import Preloader from '../../components/Preloader';
import Banner from '../../components/Banner';
import PageContent from '../../components/PageContent';
import SkillsContent from '../../components/SkillsContent';

const About = () => {

	useEffect(() => {
		document.body.classList.add('inner-page', 'about')
		return () => {
		  document.body.classList.remove('inner-page', 'about')
		}
	}, [])

  	return (	
		<>
			<Preloader />
			<Banner page={{ title: 'About Me' }} />
			<PageContent page={{ id: '3' }} />
			<SkillsContent />
		</>
	);
};
  
export default About;