import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import Typed from "react-typed";
import axios from "axios";
import HomeSkeleton from '../../components/skeleton/HomeSkeleton';

const BannerSection = () => {

    const [isLoading, setIsLoading] = useState(true);  
    const [banner, setBanner] = useState([]); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchData() {
        setIsLoading(true);
        try {
            const response = await axios.get(`${siteUrl}/wp-json/api/v2/profile/sliders`, config)
            setBanner(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line	
    },[])

    return ( 
        <section id="home" className="bg-primary d-flex fullscreen-with-header position-relative">          
            { banner && banner.length > 0 && banner.map((bannerObj, index) => (
                isLoading ? (
                    <HomeSkeleton type="slider" />
                ) : (
                <Container className="my-auto py-5" key={bannerObj.id}>
                    <Row className="py-4">
                        <Col lg={6} sm={12} className="text-center text-lg-start align-self-center order-1 order-lg-0">
                            <h1 className="text-12 fw-300 mb-0 text-uppercase text-white">{ bannerObj.title }</h1>
                            <Typed className="text-21 fw-600 text-uppercase mb-0 ms-n1 text-primary" strings={ bannerObj.keywords } typeSpeed={50} backSpeed={50} loop />
                            <p className="text-5">{ bannerObj.excerpt }</p>
                            <Link to={ bannerObj.button_link } className="btn btn-dark rounded-0 smooth-scroll mt-3">{ bannerObj.button_text }</Link>
                            <Link to={ bannerObj.link_url } className="btn btn-link text-dark smooth-scroll mt-3">
                            { bannerObj.link_text }
                                <span className="text-4 ms-2">
                                    <FontAwesomeIcon icon={ faArrowCircleDown } />
                                </span>
                            </Link>
                        </Col>
                        <Col lg={6} sm={12} className="text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
                            <div className="bg-light rounded-pill d-inline-block p-3 wow shadow-lg zoomIn">
                                <img className="img-fluid rounded-pill d-block" src={ bannerObj.featured_image } title={ bannerObj.title } alt={ bannerObj.title } />        
                            </div>
                        </Col>
                    </Row>
                </Container>
                )
            ))}
            <Link to="/about-me" className="scroll-down-arrow text-dark smooth-scroll">
                <span className="animated"><FontAwesomeIcon icon={ faArrowDown } /></span>
            </Link>
        </section>
    );
}

export default BannerSection