import { useState } from 'react';

const useIp4 = () => {

    const getIp4 = () => { 

        const ipv4 = localStorage.getItem( 'ipv4' );
        const userIp = ipv4;
        return userIp
    }

    const [ip4, setIp4] = useState( getIp4() )

    const saveIp4 = userIp => { 
        localStorage.setItem( 'ipv4', userIp );
        setIp4( userIp.ip );
    };

    return { 
        setIp4: saveIp4,
        ip4
    }
}

export default useIp4;