import React from 'react'
import Search from './Search'
import Categories from './Categories'
import PopularPosts from './PopularPosts'
import TagCloud from './TagCloud'

const Sidebar = () => {
    return (
        <div className="sidebar">
            <Search />
            <Categories />
            <PopularPosts />
            <TagCloud />
        </div>
    )
}

export default Sidebar