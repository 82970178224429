import React, { useEffect } from "react";
import Preloader from '../../components/Preloader';
import { BannerSection, AboutSection, ExpertiseSection, ResumeSection, My3eSection } from './';
import useWow from "../../utils/useWow";

const Home = () => {

	const { initWow } = useWow();
	
	useEffect(() => {
		document.body.classList.add('home')
        initWow();
		return () => {
			document.body.classList.remove('home')
		}
		// eslint-disable-next-line
    }, []);

    return (
		<>
			<Preloader />
			<BannerSection />
			<AboutSection />
      		<ExpertiseSection />
			<ResumeSection />
			<My3eSection />
		</>
    );
}
  
export default Home;