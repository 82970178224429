import React, { useState, useRef } from 'react'
import axios from 'axios';
import useToken from "../utils/useToken";
import { Form, FormGroup, FormControl, Button, FormLabel } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Comments from './Comments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const LeaveReply = ( props ) => {

    const postId = props.id;
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken(); 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [parent, setParent] = useState(0);
    const [url, setUrl] = useState('');
    const [errors, setErrors] = useState({});
    const targetRef = useRef(null);

    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    const updateVariable = (value) => { 
        setParent(value);
    };

    const capitalizeWord = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };
    
    const handleKeyUp = (e) => {
        const { name, value } = e.target;
        const newErrors = { ...errors };

        if ( value.trim() === '' ) {
            newErrors[name] = `${capitalizeWord(name)} is required`;
        } else if ( name === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ) {  
            newErrors[name] = 'A valid email address required';  
        } else {
            delete newErrors[name];
        }

        setErrors(newErrors);
    };

    const validateForm = () => {

        let isValid = true;
        const newErrors = {};

        if (name.trim() === '') {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        if (email.trim() === '') {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if ( !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ) {
            newErrors.email = 'A valid email address required';
            isValid = false;
        }

        if (comment.trim() === '') {
            newErrors.comment = 'Comment is required';
            isValid = false;
        }

        setErrors(newErrors);

        return isValid;
    }

    const handleSubmit = async (e) => { 
        e.preventDefault(); 

        const commentData = {
            'post_id': postId,
            'parent' : parent,
            'content': comment,
            'author_name': name,
            'author_email': email
        }
        
        if ( validateForm() ) {

            setName('');
            setEmail('');
            setComment('');
            setParent(0);
            setUrl('');

            try {
                const response = await axios.post( `${siteUrl}/wp-json/api/v2/content/posts/comments`, commentData, config );
                const args = {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                };

                if( response.data.status === 'success' ) {
                    toast.success( response.data.message, args );
                } else {
                    toast.error(response.data.message, args);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <>
            <Comments id={ postId } updateVariable={updateVariable} targetRef={targetRef} />
            <div className="leave_reply_wrapper" ref={targetRef} >
                <h3 className="fw-600 text-7">Leave a reply</h3>   
                <p className="text-muted mb-5">Your email address will not be published. Required fields are marked <span className="required">*</span></p>        
                <Form className="form-border" onSubmit={handleSubmit}>
                    <Row>
                        <div className="col-lg-12 col-md-12 wow fadeInUp">
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor="your_name">Comment<span className="required">*</span></FormLabel>
                                <FormControl className="fw-500 fs-6 p-2 py-1" as="textarea" name="comment" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} onKeyUp={handleKeyUp} />
                                { errors.comment && <p className="alert-danger">{errors.comment}</p> }
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-lg-6 col-md-12 wow fadeInUp">
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor="your_name">Name<span className="required">*</span></FormLabel>
                                <FormControl className="fw-500 fs-6 p-2" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} onKeyUp={handleKeyUp} />
                                { errors.name && <p className="alert-danger">{errors.name}</p> }
                            </FormGroup>
                        </div>    
                        <div className="col-lg-6 col-md-12 wow fadeInUp">
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor="your_name">URL<span className="required"></span></FormLabel>
                                <FormControl className="fw-500 fs-6 p-2" type="web" name="url" value={url} onChange={(e) => setUrl(e.target.value)} onKeyUp={handleKeyUp} />
                            </FormGroup>
                        </div> 
                    </Row>
                    <Row>     
                        <div className="col-lg-12 col-md-12 wow fadeInUp">
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor="your_name">Email Address<span className="required">*</span></FormLabel>
                                <FormControl className="fw-500 fs-6 p-2" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyUp={handleKeyUp} />
                                { errors.email && <p className="alert-danger">{errors.email}</p> }
                            </FormGroup>
                        </div>  
                    </Row> 
                    <Row>
                        <div className="col-lg-12 col-md-12 wow fadeInUp mt-3">
                            <Button type="submit" className="btn btn-dark rounded-0">Post Comment <span className="ms-3"><FontAwesomeIcon icon={ faArrowRight } /></span></Button>
                        </div>
                    </Row>       
                </Form>
                <ToastContainer />          
            </div>
        </>
    )
}

export default LeaveReply
