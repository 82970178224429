import React, { useEffect, useState } from 'react'
import axios from 'axios';
import useToken from "../utils/useToken";
import { Link } from 'react-router-dom';
import { SanitizedHTML } from '../utils/Helper';

const RelatedPosts = ( props ) => {

    const postId = props.data;
    const [response, setResponse] = useState([]); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken(); 
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    const fetchContents = async () => {

        try {
            if( postId !== undefined ) {
                const response = await axios.get( `${siteUrl}/wp-json/api/v2/content/related-posts/${postId}`, config );

                if (response.status === 401) {
                    setResponse('401');
                    return;
                }

                setResponse( response.data.items );
            }
        } catch (error) {
            console.error(error);
        }   

        console.log()
    }

    useEffect(() => {
        fetchContents(); // eslint-disable-next-line	
    },[postId])

    return (
        <div className="related_posts_wrapper">
            <ul className="d-flex align-items-start flex-wrap p-0 m-0">
                { response && response.map((post, index) => (
                    post.featured_image && <li key={ index } className="me-3">
                        <div className="post-image position-relative">
                            <img src={ post.featured_image } alt={ post.title } className="img-fluid" />
                            { post.categories && <span className="post-category position-absolute">
                                { post.categories.map((cat, index) => (
                                    <Link className="text-muted text-decoration-none p-1 me-1" key={ index } to={ `/categories/${cat.slug}` }>{ cat.name }</Link>
                                )) }
                            </span> }
                        </div>
                        <h3 className="fw-500 text-start pt-3 pb-2"><Link to={ `/blog/${post.slug}` }><SanitizedHTML html={ post.title } tag="span" /></Link></h3>
                        <div className="post-meta d-flex align-items-center pb-4 text-muted fw-500 text-uppercase">
                            <span className="post-author">
                                <Link to="/about-me" className="text-muted">
                                    <span>{ post.post_author_name }</span>
                                </Link>
                            </span>
                            <span className="post-date">{ post.post_date }</span>
                            <span className="post-view-count">{ post.page_views }</span>                         
                        </div>
                    </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default RelatedPosts