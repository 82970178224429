import React, { useState, useEffect } from 'react'
import axios from 'axios';
import useToken from "../utils/useToken";
import { Link } from 'react-router-dom';

const TagCloud = () => {

    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken(); 
    const [response, setResponse] = useState([]);

    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    const fetchContents = async () => {

        try {
            const categories = await axios.get( `${siteUrl}/wp-json/api/v2/content/tag-cloud`, config ); 

            if (categories.status === 401) {
                setResponse('401');
                return;
            }

            setResponse( categories.data.items );
        } catch (error) {
            console.error(error);
        }   
    }

    useEffect(() => {
        fetchContents(); // eslint-disable-next-line	
    },[])

    return (
        <div className="common-wrapper mb-4 p-5 position-relative">
            <h3 className="mb-4 fw-500 text-uppercase text-5">Tag Cloud</h3>
            { response && <ul className="d-flex align-items-start flex-wrap p-0 m-0 tagcloud-wrapper">
                { response.map((tag, index) => (
                    <li key={ index } className="d-flex align-items-start">
                        <Link to={ `/tag/${tag.slug}` } className="text-muted text-decoration-none" style={{ fontSize : `${tag.font_size}px` }}>
                            <span>{ tag.name }</span>
                        </Link>
                    </li>
                )) }
            </ul>
            }
        </div>
    )
}

export default TagCloud