import React from 'react'
import {Helmet} from "react-helmet";

const MetaTags = (props) => {
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const settings = JSON.parse( localStorage.getItem( 'settings' ) ); 
    const response = props.data;
    
    return (
        <Helmet>
            <title>{ `${settings.site_name } | ${ response.title}` }</title>
            <link rel="image_src" href={ response.featured_image } />
            <meta name="msapplication-TileImage" content={ response.featured_image } />
            <meta property="og:image" content={ response.featured_image } />
            <meta property="og:image:secure_url" content={ response.featured_image } />
            <meta property="og:image:width" content="1080" />
            <meta property="og:image:height" content="1080" />
            <meta property="og:image:alt" content={ response.title } />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:description" content={ response.excerpt } />
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content={ settings.site_name } />
            <meta property="og:title" content={ response.title } />
            <meta property="og:url" content={ `${siteUrl}/blog/${response.slug}` } />
            <meta property="og:updated_time" content={ response.modified_time } />
            <meta property="article:tag" content={ response.meta_tags } />
            <meta property="article:published_time" content={ response.published_time } />
            <meta property="article:modified_time" content={ response.modified_time } />
            <meta property="article:author:first_name" content={ response.author_first_name } />
            <meta property="article:author:last_name" content={ response.author_last_name } />
            <meta property="article:author:username" content={ response.author_username } />
            <meta property="twitter:partner" content="ogwp" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:image" content={ response.featured_image } />
            <meta property="twitter:image:alt" content={ response.title } />
            <meta property="twitter:title" content={ response.title } />
            <meta property="twitter:description" content={ response.excerpt } />
            <meta property="twitter:url" content={ `${siteUrl}/blog/${response.slug}` } />
            <meta itemprop="image" content={ response.featured_image } />
            <meta itemprop="name" content={ response.title } />
            <meta itemprop="description" content={ response.excerpt } />
            <meta itemprop="datePublished" content={ response.published_time } />
            <meta itemprop="dateModified" content={ response.modified_time } />
            <meta itemprop="author" content={ response.post_author_name } />
            <meta property="profile:first_name" content={ response.author_first_name } />
            <meta property="profile:last_name" content={ response.author_last_name } />
            <meta property="profile:username" content={ response.author_username } />
            <meta name="author" content={ response.post_author_name } />
        </Helmet>
    )
}

export default MetaTags