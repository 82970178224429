import React, { useState, useEffect } from 'react'
import axios from 'axios';
import useToken from "../utils/useToken";
import { Link } from 'react-router-dom';
import { SanitizedHTML } from '../utils/Helper';

const PopularPosts = () => {

    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken(); 
    const [response, setResponse] = useState([]);

    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    const fetchContents = async () => {

        try {
            const categories = await axios.get( `${siteUrl}/wp-json/api/v2/content/popular-posts`, config ); 

            if (categories.status === 401) {
                setResponse('401');
                return;
            }

            setResponse( categories.data.items );
        } catch (error) {
            console.error(error);
        }   
    }

    useEffect(() => {
        fetchContents(); // eslint-disable-next-line	
    },[])

    return (
        <div className="common-wrapper mb-4 p-5 position-relative">
            <h3 className="mb-4 fw-500 text-uppercase text-5">Popular Posts</h3>           
            { response && <ul className="d-flex align-items-start flex-wrap p-0 m-0 popular-post-wrapper">
                { response.map((post, index) => (
                    <li key={ index } className="d-flex align-items-start">
                        { post.featured_image && <img src={ post.featured_image } alt={ post.title } className="img-fluid me-3"  width="70" /> }
                        <div className="content_wrap">
                            <h5 className="fw-600 text-start text-3"><Link to={ `/blog/${post.slug}` } className="text-decoration-none"><SanitizedHTML html={ post.title } tag="span" /></Link></h5>
                            <div className="post-meta d-flex align-items-center text-muted fw-500 text-uppercase text-1">
                                <span className="post-author">
                                    <Link to="/about-me" className="text-muted text-decoration-none">
                                        <span>{ post.post_author_name }</span>
                                    </Link>
                                </span>
                                <span className="post-date">{ post.post_date }</span>                           
                            </div>
                        </div>
                    </li>
                )) }
            </ul> }          
        </div>
    )
}

export default PopularPosts