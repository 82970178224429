import React from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, FormLabel, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useForm } from "react-hook-form";
import useToken from '../utils/useToken';
import sectionTopBg from '../assets/images/section-top.webp';
import { ToastContainer, toast } from 'react-toastify';

const ContactForm = () => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken();


    const onSubmit = async (data) => {
        try {
            await saveFormData(data);
            reset();
        } catch (e) {
            alert(`An error occurred while trying to submit the contact form! ${e.message}`);
        }
    }

    const config = {
        headers:{
            'Content-type': 'multipart/form-data; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors'
    };
    
    const saveFormData = async (formData) => {

        try {
            formData['subject'] = 'Contact Us - Jinesh P.V';
            
            const response = await axios.post( `${siteUrl}/wp-json/contact-form-7/v1/contact-forms/5/feedback`, formData, config );
            const args = {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            };

            if( response.data.status === 'mail_sent' ) {
                toast.success( response.data.message, args );
            } else {
                toast.error(response.data.message, args);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <section id="contact-form" className="section inner-page">
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionTopBg })`, height: '35px', backgroundColor: '#f8f9fa' }}></div>
                <Container>                                     
                    <Row>                       
                        <Col className="mx-auto col-8 col-md-8 col-lg-8">
                            <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">Let's get in touch</h2> 
                            <Form className="form-border" onSubmit={ handleSubmit(onSubmit) }>
                                <Row className="g-4">
                                    <Col className="col-12">
                                        <FormLabel htmlFor="your_name">What is your name<span className="required">*</span></FormLabel>
                                        <FormControl id="your_name" type="text" className="py-1 fw-500 fs-6" 
                                            {...register("yourName", { 
                                                required: true, 
                                                maxLength: 10 
                                            })} 
                                        />
                                        {errors.yourName && <span className="text-error">Please enter your name</span>}
                                    </Col>
                                    <Col className="col-12">
                                        <FormLabel htmlFor="email">Your email address<span className="required">*</span></FormLabel>
                                        <FormControl id="email" type="email" className="py-1 fw-500 fs-6" 
                                            {...register("emailAddress", { 
                                                required: true, 
                                                /* eslint-disable no-useless-escape */
                                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            })} 
                                        />
                                        {errors.emailAddress && <span className="text-error">Please enter an email address</span>}
                                    </Col>
                                    <Col className="col-12">
                                        <FormLabel htmlFor="form-message">Your message<span className="required">*</span></FormLabel>
                                        <textarea id="form-message" className="form-control py-1 fw-500 fs-6" rows="4"
                                        {...register("message", { 
                                            required: true, 
                                            maxLength: 500 
                                        })} 
                                        ></textarea>
                                        {errors.message && <span className="text-error">Please enter your message</span>}
                                    </Col>
                                    <Col className="col-12 text-center text-lg-start">
                                        <button id="submit-btn" className="btn btn-dark rounded-0" type="submit">Send <span className="ms-3"><FontAwesomeIcon icon={ faArrowRight } /></span></button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>                       
                    </Row>                  
                </Container>
                <ToastContainer />
            </section>
        </>
    )
}

export default ContactForm
