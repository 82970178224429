import React, { useState, useEffect } from 'react'
import axios from 'axios';
import useToken from "../utils/useToken";
import { SanitizedHTML } from '../utils/Helper';
import authorImg from '../assets/images/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

const Comments = ( props, updateVariable, targetRef ) => {

    const postId = props.id;
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const { token } = useToken(); 
    const [response, setResponse] = useState([]);
    const [count, setCount] = useState(0);

    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${token}`
        },
        mode: 'no-cors',
    };

    const fetchContents = async () => {

        try {

            if( postId !== undefined ) {
                const comments = await axios.get( `${siteUrl}/wp-json/api/v2/content/posts/comments/${postId}`, config );             

                if (comments.status === 401) {
                    setResponse('401');
                    return;
                }

                setResponse( comments.data.items );
                setCount( comments.data.count );
            }
        } catch (error) {
            console.error(error);
        }   
    }

    useEffect(() => {
        fetchContents(); // eslint-disable-next-line	
    },[postId])

    const handleClick = ( commentId ) => {
        props.updateVariable( commentId );
        props.targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            { count > 0 && <div className="comments_wrapper mb-5">
                <h3 className="mb-5 fw-600 text-7">{ count === 1 ? `${ count } Comment` : `${ count } Comments` }</h3>
                { response && <ul className="comment_wrapper p-0 m-0">
                    { response.map((comment, index) => (              
                    <>
                        <Comment key={ index } comment={ comment } />   
                        { comment.childrens && Object.keys(comment.childrens).map((key) =>(
                            <><li key={ comment.childrens[key].id } className="mt-3 mb-3 ms-5 p-3 comment-reply rounded-1 d-flex position-relative">
                                <div className="comment_icon"><img src={ authorImg } alt={ comment.childrens[key].comment_author } width={70} className="img-fluid rounded-circle me-2" /></div>
                                <div className="comment_text">
                                    <h4 className="lh-1">{ comment.childrens[key].comment_author }</h4>
                                    <span>{ comment.childrens[key].comment_date }</span>
                                    <SanitizedHTML className="text-2 mt-3 comment-content" html={ comment.childrens[key].comment_content } tag="p" />
                                </div>
                                <button className="btn btn-link post-reply text-dark smooth-scroll" onClick={() => handleClick(comment.childrens[key].id) }><span className="text-4 me-2"><FontAwesomeIcon icon={ faReply } /></span>Reply</button>
                            </li> 
                            { comment.childrens[key].childrens && Object.keys(comment.childrens[key].childrens).map((inx) =>(
                                <li key={ comment.childrens[key].childrens[inx].id } className="mt-3 mb-3 p-3 comment-reply child rounded-1 d-flex position-relative">
                                    <div className="comment_icon"><img src={ authorImg } alt={ comment.childrens[key].childrens[inx].comment_author } width={70} className="img-fluid rounded-circle me-2" /></div>
                                    <div className="comment_text">
                                        <h4 className="lh-1">{ comment.childrens[key].childrens[inx].comment_author } </h4>
                                        <span>{ comment.childrens[key].childrens[inx].comment_date }</span>
                                        <SanitizedHTML className="text-2 mt-3 comment-content" html={ comment.childrens[key].childrens[inx].comment_content } tag="p" />
                                    </div>
                                    <button className="btn btn-link post-reply text-dark smooth-scroll" onClick={() => handleClick(comment.childrens[key].childrens[inx].id) }><span className="text-4 me-2"><FontAwesomeIcon icon={ faReply } /></span>Reply</button>
                                </li>  
                            )) }</>   
                        )) }                      
                    </>                                                                             
                    )) }
                </ul> }
            </div> }            
        </div>
    )
}

const Comment = ({ comment }) => {
    return (
        <li key={ comment.id } className="mt-3 mb-3 p-3 comment-thread rounded-1 d-flex position-relative">
            <div className="comment_icon"><img src={ authorImg } alt={ comment.comment_author } width={70} className="img-fluid rounded-circle me-2" /></div>
            <div className="comment_text">
                <h4 className="lh-1">{ comment.comment_author } </h4>
                <span>{ comment.comment_date }</span>
                <SanitizedHTML className="text-2 mt-3 comment-content" html={ comment.comment_content } tag="p" />
            </div>                            
            <button className="btn btn-link post-reply text-dark smooth-scroll" onClick={() => Comments.handleClick(comment.id) }><span className="text-4 me-2"><FontAwesomeIcon icon={ faReply } /></span>Reply</button>
        </li>
    );
}
export default Comments