import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionGreyBg from '../../assets/images/section-grey.webp';
import sectionGreyBg1 from '../../assets/images/bg_pattern.svg';
import HomeSkeleton from '../../components/skeleton/HomeSkeleton';

const ResumeSection = () => {
    
    const [educations, setEducations] = useState([]);  
    const [experiences, setExperiences] = useState([]);
    const [skills, setSkills] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchData() {
        setIsLoading(true);
        axios.all(
            [ 
                axios.get( `${siteUrl}/wp-json/api/v2/profile/educations`, config ),
                axios.get( `${siteUrl}/wp-json/api/v2/profile/experiences`, config ),
                axios.get( `${siteUrl}/wp-json/api/v2/profile/skills?per_page=20`, config )
            ]
        )
        .then(
            axios.spread(
                ( educationResponse, experienceResponse, skillsResponse ) => {  
                setEducations( educationResponse.data );
                setExperiences( experienceResponse.data );
                setSkills( skillsResponse.data );
                setIsLoading(false);
            }
        ))
        .catch(error => console.log( error ) );
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line	
    },[])

    return (
        <section id="resume" className="section" style={{ backgroundImage: `url(${ sectionGreyBg1 })` }}>
            <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreyBg })`, height: '35px' }}></div>
            <Container> 
                <div className="position-relative d-flex text-center mb-5">
                    <h2 className="text-22 text-light opacity-4 text-uppercase fw-600 w-100 mb-0 font-primary wow fadeInUp">summary</h2>
                    <p className="text-10 text-dark fw-600 position-absolute w-100 align-self-center font-secondary lh-base mb-0 wow fadeInUp">Resume</p>
                </div>
                <Row className="g-5 mt-5"> 
                    <Col lg={6} md={6} xs={12} className="wow fadeInUp">
                        <h2 className="text-6 fw-600 mb-4 pb-2">My Education</h2>
                        <div className="border-start border-2 border-primary ps-3">
                            { isLoading ? (
                                <HomeSkeleton type="resume" />
                            ) : (
                                educations && educations.length > 0 && educations.map((educationObj, index) => (
                                <div key={ educationObj.id }>
                                    <h3 className="text-5">{ `${ educationObj.name_of_degree } in ${ educationObj.title }` }</h3>
                                    <p className="mb-2"><span className="text-secondary">{ educationObj.course_duration }</span></p>
                                    <p className="text-muted">{ educationObj.college }</p>           
                                    <hr className="my-4" />
                                </div>
                            )))
                            }   
                        </div>
                    </Col>
                    <Col lg={6} md={6} xs={12} className="wow fadeInUp" data-wow-delay="0.2s">
                        <h2 className="text-6 fw-600 mb-4 pb-2">My Experience</h2>
                        <div className="border-start border-2 border-primary ps-3">
                        { isLoading ? (
                            <HomeSkeleton type="resume" />
                        ) : (
                                experiences && experiences.length > 0 && experiences.map((experienceObj, index) => (
                            <div key={ experienceObj.id }>
                                <h3 className="text-5">{ experienceObj.designation }</h3>
                                <p className="mb-2">{ `${ experienceObj.title } / ` }<span className="text-secondary">{ ( experienceObj.current === '1' ) ? `${ experienceObj.duration } - Current` : experienceObj.duration }</span></p>
                                <p className="text-muted">{ experienceObj.excerpt }</p>
                                <hr className="my-4" />
                            </div>
                        )))
                        }
                        </div>
                    </Col>
                </Row>
                <h2 className="text-6 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
                <Row className="gx-5">                   
                { isLoading ? (
                    <HomeSkeleton type="skill" />
                ) : ( skills && skills.length > 0 && skills.map((skillObj, index) => (
                    <Col md={3} className="wow fadeInUp" key={ skillObj.id }>
                        <p className="fw-500 text-start mb-2">{ skillObj.title }</p>
                        <div className="progress progress-sm mb-4">
                            <div className="progress-bar" role="progressbar" style={{ 'width': `${ skillObj.percentage }% ` }} aria-valuenow={ skillObj.percentage } aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </Col>
                )))
                }                  
                </Row>
            </Container>
        </section>
    );
}

export default ResumeSection