import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import useToken from '../utils/useToken';
import SocialShare from './SocialShare';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SanitizedHTML } from '../utils/Helper';

const BlogList = (props) => {

    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const posts = props.data;
    const [likes, setLikes] = useState({});
    const [isButtonClicked, setIsButtonClicked] = useState([]); 
    const { token } = useToken(); 

    const doSavePostInHeart = async (postId, currCount, nonce) => { 
        const likeCount = parseInt((likes[postId] || currCount) + 1);
        setLikes({ ...likes, [postId]: likeCount })

        if( !isNaN(likeCount) ) {

            const config = {
                headers:{
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'no-cors'
            };

            const params = {
                'liked_count' : likeCount,
                'post_id' : postId
            }

            try {
                const response = await axios.put( `${siteUrl}/wp-json/api/v2/content/posts/liked`, params, config ); 
                const args = {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                };
                if( response.data.status === 'success' ) {
                    toast.success( response.data.message, args );
                } else {
                    toast.error(response.data.message, args);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const handleIsButtonClick = (index, postId) => {    

        setIsButtonClicked( prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    }

    useEffect(() => {
        doSavePostInHeart(); // eslint-disable-next-line	
    },[])   

    return (
        posts && posts.map((post, index) => (
            <div className="box wow p-4 mb-5 fadeInUp" key={ post.id }>
                <div className="post-image">
                    { post.featured_image && <img src={ post.featured_image } alt={ post.title } className="img-fluid" /> }
                    <span className="liked-count-wrap text-muted">
                        <button onClick={() => [doSavePostInHeart(post.id, parseInt(post.liked_count), post.nonce), handleIsButtonClick(index, post.id)]} disabled={isButtonClicked[index]}>
                            <FontAwesomeIcon icon={ !likes[post.id] ? farHeart : faHeart }/>
                        </button>                                   
                        <FormLabel>{ likes[post.id] || post.liked_count }</FormLabel>
                    </span>
                </div>
                <ToastContainer />
                <h2 className="fw-500 text-start pt-5 pb-2"><Link to={ `/blog/${post.slug}` }><SanitizedHTML html={ post.title } tag="span" /></Link></h2>
                <div className="post-meta d-flex align-items-center pb-4 text-muted fw-500 text-uppercase">
                    <span className="post-author">
                        <Link to="/about-me" className="d-flex align-items-center text-muted">
                            { post.post_author_url && <img src={ post.post_author_url } alt={ post.post_author_name } width={40} className="img-fluid rounded-circle me-2" /> }
                            <span>{ post.post_author_name }</span>
                        </Link>
                    </span>
                    <span className="post-date">{ post.post_date }</span>
                    <span className="post-reading-time">{ post.reading_time }</span>
                    <span className="post-comment-count"><Link className="text-muted" to={ `/blog/${post.slug}/#comments` }>{ post.comment_count }</Link></span>
                    <span className="post-view-count">{ post.page_views }</span>
                    { post.categories && <span className="post-category">
                        <span>Categories</span>
                        { post.categories.map((cat, index) => (
                            <Link className="text-muted" key={ index } to={ `/category/${cat.slug}` }>{ cat.name }</Link>
                        )) }
                    </span> }
                </div>
                <p className="text-4 text-muted">{ post.excerpt }</p>
                <div className="social_sharing">
                    <SocialShare title={ post.title } link={ `${window.location.href}/${post.slug}` } />
                    <span className="readmore ms-4 text-muted">
                        <Link to={ `/blog/${post.slug}` } className="d-flex align-items-center text-uppercase fw-700">Read more</Link>
                    </span>
                </div>
            </div>
        ))
    )
}

export default BlogList