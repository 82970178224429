import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Social } from '../components';
import { NavLink } from "react-router-dom";

const Footer = () => {

    const settings = JSON.parse( localStorage.getItem( 'settings' ) );

    return (
        <>
            <footer id="footer" className="section bg-dark text-white">
                <Container>
                    <Row>
                        <Col lg={12} className="d-flex justify-content-center wow fadeInUp">
                            <Social />
                        </Col>
                    </Row>
                    <Row> 
                        <Col lg={12} className="d-flex justify-content-center wow fadeInUp">       
                            <ul className="navbar-nav">
                                <li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/">Home</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/about-me">About</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/blog">Blog</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/resume">Resume</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link smooth-scroll" activeclassname="active" to="/connect-me">Contact</NavLink></li>
                            </ul>
                        </Col>
                    </Row>
                    
                </Container>
            </footer>
            <div className="subfooter">
                <Container>
                    <Row>
                        <Col lg={6} xs={12} className="d-flex justify-content-end wow fadeInUp">
                            <p className="mb-2 mb-lg-0">{ settings && settings.copyright }</p>
                        </Col>
                        <Col lg={6} xs={12} className="d-flex justify-content-start wow fadeInUp last-child">
                            <p className="mb-0 text-center text-lg-end">{ settings && settings.owned_by }</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer
