import WOW from 'wowjs';

const useWow = () => {
    
    const initWow = () => {
        const wow = new WOW.WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
            live: false,
            scrollContainer: null
          });
        wow.init();
    }
    
    return { initWow }
}

export default useWow