import React from 'react'

const Educations = ( props ) => {
    const educations = props.data;
    return (
        <div>
            { educations && educations.length > 0 && educations.map((educationObj, index) => (
            <div key={ educationObj.id }>
                <h3 className="text-5">{ `${ educationObj.name_of_degree } in ${ educationObj.title }` }</h3>
                <p className="mb-2"><span className="text-secondary">{ educationObj.course_duration }</span></p>
                <p className="text-muted">{ educationObj.college }</p>
            
                <hr className="my-4" />
            </div>
            )) }
        </div>
    )
}

export default Educations