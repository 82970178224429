import React, { useEffect } from 'react';
import Preloader from '../../components/Preloader';
import Banner from '../../components/Banner';
import Info from './Info';
import ContactForm from '../../components/ContactForm';

const Contact = () => {

	useEffect(() => {
		document.body.classList.add('inner-page', 'contact-me')
		return () => {
		  	document.body.classList.remove('inner-page', 'contact-me')
		}
	}, [])

	return (
		<>
			<Preloader />
			<Banner page={{ title: 'Contact Me' }} />
			<Info />
			<ContactForm />
		</>
	);
};
  
export default Contact;