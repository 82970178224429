import React from 'react';

const Social = () => {

	const settings = JSON.parse( localStorage.getItem( 'settings' ) );

	return (   
		<ul className="social-icons">
			<li className="social-icons-facebook">
				<a href={ settings && settings.facebook } target="_blank" rel="noopener noreferrer" title="Facebook">
					fb.
				</a>
			</li>
			<li className="social-icons-twitter">
				<a href={ settings && settings.twitter } target="_blank" rel="noopener noreferrer" title="Twitter">
					tw.
				</a>
			</li>
			<li className="social-icons-linkedin">
				<a href={ settings && settings.linkedin } target="_blank" rel="noopener noreferrer" title="Linked In">
					ig.
				</a>
			</li>
			<li className="social-icons-instagram">
				<a href={ settings && settings.instagram } target="_blank" rel="noopener noreferrer" title="Instagram">
					li.
				</a>
			</li>
			<li className="social-icons-whatsapp">
				<a href={ settings && settings.whatsapp } target="_blank" rel="noopener noreferrer" title="WhatsApp">
					wa.
				</a>
			</li>
		</ul>	
	)
}

export default Social