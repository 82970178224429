import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import sectionGreyBg from '../../assets/images/bg_pattern.svg';
import sectionGreenBg from '../../assets/images/section-green.webp';
import { Mailto, SanitizedHTML } from '../../utils/Helper';
import { Social } from '../../components';

const Info = () => {

    const [content, setContent] = useState({
        id: null,
        content: null,
        emailAddress: null,
        place: null,
        phoneNumber: null
    });
  
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchContents() {

        try {
            const response = await axios.get(`${siteUrl}/wp-json/wp/v2/pages/179`, config);
            setContent({
                id: response.data.id,
                content: response.data.content,
                emailAddress: response.data.email_address,
                place: response.data.place,
                phoneNumber: response.data.phone_number

            })
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchContents();
        // eslint-disable-next-line	
    },[])

    return (
        <section id="contact" className="section"  style={{ backgroundImage: `url(${ sectionGreyBg })` }}>
            <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreenBg })`, height: '35px' }}></div>
            <Container>
                <Row className="g-5 mt-5">
                    <Col lg={9} className="wow fadeInUp">
                        <SanitizedHTML className="text-3 post-content" html={ content.content } tag="p" />
                    </Col>                           
                    <Col lg={3}>
                        <h3>Contact me</h3>
                        <Row>
                            <Col lg={12} className="wow fadeInUp">
                                <p className="text-muted fw-500 mb-0">Phone:</p>
                                <p className="text-4 text-dark fw-600 mb-0">{ content.phoneNumber }</p>
                                <hr />
                            </Col>
                            <Col lg={12} className="wow fadeInUp" data-wow-delay="0.2s">
                                <p className="text-muted fw-500 mb-0">Email:</p>
                                <p className="text-4 fw-600 mb-0"><Mailto email={ content.emailAddress } subject="Hello" body="Hello Jinesh P.V">{ content.emailAddress }</Mailto></p>
                                <hr />
                            </Col>
                            <Col lg={12} className="wow fadeInUp" data-wow-delay="0.4s">
                                <p className="text-muted fw-500 mb-0">From:</p>
                                <p className="text-4 text-dark fw-600 mb-0">{ content.place }</p>
                            </Col>
                        </Row><br />
                        <h3>Social me</h3>
                        <Social />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Info