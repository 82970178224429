import React from 'react'

const SocialShare = (post) => {

    const FbShareUrl = `https://www.facebook.com/sharer.php?u=${ post.link }&t=${ post.title }`;
    const TwShareUrl = `https://twitter.com/intent/tweet?url=${ post.link }`;
    const LiShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${ post.link }&title=${ post.title }`;
    const WaShareUrl = `https://api.whatsapp.com/send?text=${ post.title } ${ post.link }`;

    return (
        post && <ul className="social-icons sharing">
            <li className="social-icons-facebook"><a href={ FbShareUrl } target="_blank" rel="noopener noreferrer" title="Facebook">fb.</a></li>
            <li className="social-icons-twitter"><a href={ TwShareUrl } target="_blank" rel="noopener noreferrer" title="Twitter">tw.</a></li>
            <li className="social-icons-linkedin"><a href={ LiShareUrl } target="_blank" rel="noopener noreferrer" title="Linked In">ig.</a></li>
            <li className="social-icons-whatsapp"><a href={ WaShareUrl } target="_blank" rel="noopener noreferrer" title="Whatsapp">wa.</a></li>       
        </ul>
    )
}

export default SocialShare