import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionGreenBg from '../../assets/images/section-green.webp';
import wrapCenterBg from '../../assets/images/wrap-center.webp';
import HomeSkeleton from '../../components/skeleton/HomeSkeleton';

const AboutSection = () => {
    const [isLoading, setIsLoading] = useState(true); 
    const [content, setContent] = useState({
        id: null,
        title: null,
        content: null,
        emailAddress: null,
        dateOfBirth: null,
        place: null,
        phoneNumber: null,
        yearsOfExperience: null,
    }); 
    const pageId = 3;
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };
    async function fetchContents() {
        setIsLoading(true);
        try {
            const response = await axios.get(`${siteUrl}/wp-json/wp/v2/pages/${pageId}`, config);
            setContent({
                id: response.data.id,
                title: response.data.full_name,
                content: response.data.excerpt,
                emailAddress: response.data.email_address,
                dateOfBirth: response.data.date_of_birth,
                place: response.data.place,
                phoneNumber: response.data.phone_number,
                yearsOfExperience: response.data.years_of_experience,

            })
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchContents();
        // eslint-disable-next-line	
    },[])

    function Mailto({ email, subject, body, ...props }) {
        return (
            <a className="text-dark" href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
                {props.children}
            </a>
        );
    }

    return (
        <section id="about" className="section" style={{ backgroundImage: `url(${ wrapCenterBg })` }}>
            <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreenBg })`, height: '35px' }}></div>          
            <Container> 
                <div className="position-relative d-flex text-center mb-5">
                    <h2 className="text-22 text-light opacity-4 text-uppercase fw-600 w-100 mb-0 font-primary wow fadeInUp">About Me</h2>
                    <p className="text-10 text-dark fw-600 position-absolute w-100 align-self-center font-secondary lh-base mb-0 wow fadeInUp">Know Me More</p>
                </div> 
                { isLoading ? (
                    <HomeSkeleton type="about" />
                ) : (    
                <>             
                    <Row>
                        <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
                            <h2 className="text-8 fw-400 mb-3">Hi, I'm <span className="fw-700 border-bottom border-3 border-secondary">{ content.title }</span></h2>
                            <p className="text-5">{ content.content }</p>
                        </div>
                        <Col className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="featured-box style-4">
                                <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                                    <span className="wow heartBeat" data-wow-delay="1.3s">{ content.yearsOfExperience }</span>
                                </div>
                                <h3 className="text-7 wow rubberBand" data-wow-delay="2s">Years of <span className="fw-700">Experience</span></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="gy-3 mt-4">
                        <Col className="col-6 col-lg-3 wow fadeInUp">
                            <p className="text-muted fw-500 mb-0">Phone:</p>
                            <p className="text-4 text-dark fw-600 mb-0">{ content.phoneNumber }</p>
                        </Col>
                        <Col className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                            <p className="text-muted fw-500 mb-0">Email:</p>
                            <p className="text-4 fw-600 mb-0"><Mailto email={ content.emailAddress } subject="Hello" body="Hello Jinesh P.V">{ content.emailAddress }</Mailto></p>
                        </Col>
                        <Col className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                            <p className="text-muted fw-500 mb-0">Date of birth:</p>
                            <p className="text-4 text-dark fw-600 mb-0">{ content.dateOfBirth }</p>
                        </Col>
                        <Col className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                            <p className="text-muted fw-500 mb-0">From:</p>
                            <p className="text-4 text-dark fw-600 mb-0">{ content.place }</p>
                        </Col>
                    </Row>
                </>
                )}
            </Container>
        </section>
    );
}

export default AboutSection