import React from 'react'
import { Link } from 'react-router-dom';

const Tags = (props) => {
    const tags = props.data;
    return (
        <>
        { tags &&
            <div className="tag_wrapper">
                <ul className="tag_wrapper d-flex align-items-start p-0 m-0">
                    { tags.map((tag, index) => (
                        <li key={ index } className="me-2"><Link className="text-2 text-decoration-none" to={ `/tag/${tag.slug}` }>{ tag.name }</Link></li>
                    )) 
                    }
                </ul>
            </div>
        } 
        </>
    )
}

export default Tags