import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionTopBg from '../assets/images/section-top.webp';
import HomeSkeleton from './skeleton/HomeSkeleton';

const SkillsContent = ({ type }) => { 

    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState([]); 
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };
    async function fetchContents() {

        setIsLoading(true);
        try {
            const response = await axios.get( `${siteUrl}/wp-json/api/v2/profile/skills?per_page=20`, config );
            setResponse( response.data );
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }   
    }

    useEffect(() => {
        fetchContents();
        // eslint-disable-next-line	
    },[])

    return (
        <>             
            <section id="resume" className="section inner-page">
                <div className="section-decoration top" style={{ backgroundImage: `url(${ sectionTopBg })`, height: '35px', backgroundColor: '#f8f9fa' }}></div>
                <Container> 
                    <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
                    <Row className="gx-5">                   
                    { isLoading ? (
                        <HomeSkeleton type="skill" />
                    ) : (
                        response && response.length > 0 && response.map((responseObj, index) => (
                        <Col md={3} xs={6} className="wow fadeInUp" key={ responseObj.id }>
                            <p className="fw-500 text-start mb-2">{ responseObj.title }</p>
                            <div className="progress progress-sm mb-4">
                                <div className="progress-bar" role="progressbar" style={{ 'width': `${ responseObj.percentage }% ` }} aria-valuenow={ responseObj.percentage } aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </Col>
                    )))
                    }                  
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SkillsContent
