import React, { useState} from 'react'
import { Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

const Search = () => {

    const [search, setSearch] = useState('');
    const navigate = useNavigate();   
    const handleSubmit = async (e) => { 
        navigate(`/blog?s=${search}`);
    };

    return (
        <div className="search_wrapper common-wrapper p-5 mb-4 position-relative">
            <h3 className="mb-4 fw-500 text-uppercase text-5">Search</h3>
            <Form onSubmit={handleSubmit}>
                <FormGroup className="position-relative">
                    <FormControl className="fw-500 fs-6 pt-2 pb-2" type="text" name="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search here..." />
                    <Button type="submit" className="fw-700 fs-6 position-absolute"><FontAwesomeIcon icon={ faSearch } /></Button>
                </FormGroup>
            </Form>
        </div>
    )
}

export default Search
