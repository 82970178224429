import React from 'react';
import { Container } from 'react-bootstrap';
import sectionBg from '../assets/images/banner.webp';

const Banner = ({ page }) => {

    return (
        <section className="banner bg-primary" style={{ backgroundImage: `url(${ sectionBg })` }}>
            <Container>
                <div className="col-lg-12 text-center wow fadeInUp">
                    <h1>{ page.title }</h1>
                </div>
            </Container>
        </section>
    )
}

export default Banner