import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sectionGreyBg from '../../assets/images/bg_pattern.svg';
import sectionGreenBg from '../../assets/images/section-green.webp';
import Educations from '../../components/Educations';
import Experiences from '../../components/Experiences';
import DownloadPDFButton from '../../components/DownloadPDFButton';

const EducationExperience = () => {

    const [educations, setEducations] = useState([]);  
    const [experiences, setExperiences] = useState([]);
    const siteUrl = process.env.REACT_APP_API_SITE_URL;
    const config = {
        headers:{
            'Content-type': 'application/json; charset=UTF-8'
        }
    };

    async function fetchData() {

        axios.all([ axios.get( `${siteUrl}/wp-json/api/v2/profile/educations`, config ),
            axios.get( `${siteUrl}/wp-json/api/v2/profile/experiences`, config ) ])
        .then(axios.spread(( educationResponse, experienceResponse, skillsResponse ) => {  
            setEducations( educationResponse.data );
            setExperiences( experienceResponse.data );
        }))
        .catch(error => console.log( error ) );
    }

    useEffect(() => {
		document.body.classList.add('inner-page', 'resume')
		fetchData();
        	
		return () => {
		  	document.body.classList.remove('inner-page', 'resume')		  	
		}
		// eslint-disable-next-line
	}, [])

    return (
        <section id="resume" className="section"  style={{ backgroundImage: `url(${ sectionGreyBg })` }}>
			<div className="section-decoration top" style={{ backgroundImage: `url(${ sectionGreenBg })`, height: '35px' }}></div>
			<Container> 
				<Row className="g-5 mt-5"> 
					<Col className="col-lg-6 col-12 wow fadeInUp">
						<h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
						<div className="border-start border-2 border-primary ps-3">
							<Educations data={ educations } />
						</div>
					</Col>
					<Col className="col-lg-6 col-12 wow fadeInUp" data-wow-delay="0.2s">
						<h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
						<div className="border-start border-2 border-primary ps-3">
							<Experiences data={ experiences } />
						</div>
					</Col>
				</Row>
				<Row className="mt-5 download_resume">
					<Col className="col-lg-12 col-12 wow fadeInUp d-flex justify-content-center" data-wow-delay="0.2s">
						<DownloadPDFButton />
					</Col>
				</Row>
			</Container>
		</section>
    )
}

export default EducationExperience